import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pl-fep-footer',
  templateUrl: './fep-footer.component.html',
  styleUrl: './fep-footer.component.scss'
})
export class FepFooterComponent implements OnInit {

  copyrightYear: string;

  ngOnInit() {
    this.copyrightYear = new Date().getFullYear().toString();
  }

}
