<footer *ngIf="brandType != 'PremeraFep'">
  <div id="pbc-footer-bottom" *ngIf="lob == 'Premera'">
    <hr />
    <h2 class="footer-heading" role="heading" aria-level="2" aria-label="language-support">Language support</h2>
    <p id="language-support">
      <a *ngFor="let lang of footerSettings?.languages" lang="{{ lang.languageCode }}"
         pbcAnalyticsTag data-analytics-override-route="footer"
         [attr.data-analytics-title]="lang.name"
         href="{{ footerSettings?.pdfUrl + '#' + lang.name}}"
         target="_blank"
         rel="noopener"
         title="{{ lang.name }}">
        {{ lang.nativeName }}
      </a>
    </p>
    <p id="premera-legal" class="legal" [innerHtml]="footerSettings?.legalDisclaimerHtml"></p>
    <p id="premera-copyright" class="copyright" [innerHtml]="copyrightText"></p>
  </div>

  <div id="lw-footer" class="d-print-none d-md-block footer" *ngIf="lob == 'LifeWise'">
    <div id="lw-footer-top" class="footer-top">
      <p id="lw-legal" class="legal" [innerHtml]="footerSettings?.legalDisclaimerHtml"></p>
      <p id="lw-copyright" class="copyright" [innerHtml]="copyrightText"></p>
    </div>
    <div id="lw-footer-bottom">
      <ul class="language-support-section">
        <li>
          <span id="language-support" aria-label="language-support">Language support: </span>
        </li>
        <li *ngFor="let lang of footerSettings?.languages"
            id="lw-language-support-{{lang.name}}"
            lang="{{ lang.languageCode }}">
          <a pbcAnalyticsTag data-analytics-override-route="footer"
             [attr.data-analytics-title]="lang.name"
             href="{{ footerSettings?.pdfUrl + '#' + lang.name}}"
             target="_blank"
             rel="noopener"
             title="{{ lang.name }}">
            {{ lang.nativeName }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
<pl-fep-footer *ngIf="brandType == 'PremeraFep'"></pl-fep-footer>
