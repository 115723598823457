<footer class="c-footer" aria-label="footer navigation">
  <div class="c-footer__main">
    <div class="l-container ">
      <div class="c-footer__top">
        <a target="_blank" href="https://www.fepblue.org" class="a-logo" rel="home">
          <img class="a-logo__img" src="/assets/images/fep-logo.svg" alt="Blue Cross Blue Shield Federal Employee Program Home">
        </a><!--end c-logo__link-->
      </div>
      <div class="c-footer__mid">
        <div>
          <a target="_blank" href="https://www.fepblue.org/contact-us" class="c-footer__title">Contact Us</a>
          <ul class="c-footer__contact">
            <li><span>National Information Center</span><a target="" href="tel:18004112583">1 (800) 411-BLUE</a></li>
          </ul>
        </div>
      </div>
      <ul class="m-nav">
        <li class="m-nav__item">
          <a target="_blank" href="http://www.opm.gov/" class="m-nav__link m-nav__link--faded">OPM</a>
        </li>
        <li class="m-nav__item">
          <a target="_blank" href="https://www.fepblue.org/legal/terms-privacy" class="m-nav__link m-nav__link--faded">Terms &amp; Privacy</a>
        </li>
        <li class="m-nav__item">
          <a target="_blank" href="https://www.fepblue.org/legal/rights-responsibilities" class="m-nav__link m-nav__link--faded">Rights &amp; Responsibilities</a>
        </li>
        <li class="m-nav__item">
          <a target="_blank" href="https://www.fepblue.org/legal/policies-guidelines" class="m-nav__link m-nav__link--faded">Policies &amp; Guidelines</a>
        </li>
      </ul>
      <ul class="c-footer__lang">
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group1" lang="es">Español</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group1" lang="zh">繁體中文</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group1" lang="vi">Tiếng Việt</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group2" lang="ko">한국어</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group2" lang="tl">Tagalog</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group2" lang="ru">Русский</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group3" lang="ar">العربية</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group3" lang="fr">Kreyòl</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group3" lang="fr">Français</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group4" lang="pt">Português</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group4" lang="pl">Polski</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group4" lang="ja">日本語</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group5" lang="it">Italiano</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group5" lang="de">Deutsch</a>
        </li>
        <li>
          <a target="_blank" href="https://www.fepblue.org/other-language-resources#group5" lang="fa">فارسی</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="c-footer__sub">
    <div class="l-container c-footer__sub-inner">
      <img class="a-logo__img" src="/assets/images/fep-logo-no-text.svg" alt="BCBS Federal Employee Program Logo">
      ©{{copyrightYear}} Blue Cross Blue Shield Association. All rights reserved.
    </div>
  </div>
</footer>
